import request,{ post } from '@/utils/request';

export const fetchProductDetailList = async data => {
  return await post('', data)
}

export const fetchSalesmanList = async data => {
  return await post('/appdealer/api/saleman/list', data)
}

export const fetchSDLTerminalList = async data => {
  return await post('/appdealer/api/terminal/list', data)
}

export const fetchZYTerminalList = async data => {
  return await post('/appdealer/api/dist/terminal/list', data)
}

export const fetchSDLPotentialList = async data => {
  return await post('/appdealer/api/potential/list', data)
}

export const fetchZYPotentialList = async data => {
  return await post('/appdealer/api/dist/potential/list', data)
}

export const fetchSDLGrowerList = async data => {
  return await post('/appdealer/api/bigfarmers/get_bigFarmersList', data)
}

export const fetchZYGrowerList = async data => {
  return await post('/appdealer/api/dist/bigfarmers/list', data)
}

export const fetchSDLModelFieldList = async data => {
  return await post('/appdealer/api/modelfield/list', data)
}

export const fetchZYModelFieldList = async (data) => {
  return request(
    "POST",
    "/appdealer/api/dist/modelfield/list",
    {},
    data,
    {},
    { isRequestWithCoordinate: true }
  );
};

export const fetchDealerList = async data => {
  return await post('/appdealer/api/dealer/get_dealerList', data)
}

export const fetchPlantList = async data => {
  return await post('/appdealer/api/plant/list', data)
}

export const fetchSendPersonList = async data => {
  return await post('/appdealer/api/saleman/list', data)
}

export const fetchDictList = async data=>{
  return await post('/appdealer/api/dict/get_DictList', data)
}

export const fetchReceiveAddressList = async data =>{
  return await post('/appdealer/api/eb/order/getReceiveAddress', data)
}

export const fetchProductList = async data => {
  return await post('/appdealer/api/eb/order/getmateriacorellist', data)
}

export const fetchProductTypeList = async data => {
  return await post('/appdealer/api/dict/get_DictList', data)
}

export const fetchSetList = async data => {
  return await post('/appdealer/api/eb/order/getSetmealList', data)
}

export const fetchMaterialPrice = async data => {
  return await post('/appdealer/api/eb/order/getmaterialprice', data)
}

export const fetchBrandList = async () => {
  return await post('/appdealer/api/dict/get_DictList', {type: 'crm_compete_brand'})
}